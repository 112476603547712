export default class {

	/**
	 * Fill queues table; called from init(), and from the filter field
	 *     on-change functions
	 * @returns {void}
	 */
	fillQueuesTable() {
		var table = $('#tbl_queues');
		if (table.length === 0) {
			return;
		}
		var tbody = table.find('tbody');

		var form_data = {
			action: 'get_table',
			chain_id: this.fld_chain.val(),
			chain_link_id: this.fld_chain_link.val()
		};

		$.ajax({
			url: window.urls.ajax_index,
			method: 'post',
			dataType: 'json',
			data: form_data,
			success: function (response) {
				if (response.success) {
					tbody.html(response.html);
				} else {
					// @todo: errorModal o.i.d. voor gebruiken:
					alert('Error!');
				}
			},
			error: function (x, y, z) {
				// @todo: errorModal o.i.d. voor gebruiken:
				alert('Error!');
				console.log(x, y, z);
			}
		});
	}

	/**
	 * Init chain field in filter: set function on on-change event: if chain
	 *     chosen, get the chain links that belong to it
	 * @param {QueuesPage} that
	 */
	initFilterChain(that) {
		that.container.on('change', '[name="fld_filter_chain"]', function () {
			var new_val = parseInt($(this).val());

			that.fld_chain_link.empty().prop('disabled', true);

			if (new_val === -1) {
				that.fillQueuesTable();
				return;
			}

			$.ajax({
				url: '/queues/ajax_index',
				method: 'post',
				dataType: 'json',
				data: {
					action: 'get_chain_links',
					chain_id: new_val
				},
				success: function (response) {
					for (var i = 0; i < response.chain_links.length; i++) {
						var link = response.chain_links[i];
						var option = new Option(link.txt, link.id);
						that.fld_chain_link.append(option);
					}
					that.fld_chain_link.prop('disabled', false);
					that.fillQueuesTable();
				}
			});
		});

	}

	/**
	 * Init chain link field in filter: set function on on-change event
	 * @param {QueuesPage} that
	 */
	initFilterChainLink(that) {
		that.container.on('change', '[name="fld_filter_chain_link"]', function () {
			that.fillQueuesTable();
		});
	}

	/**
	 * Init fields in filter
	 */
	initFilter() {
		this.initFilterChain(this);
		this.initFilterChainLink(this);
	}

	/**
	 * Init: set class props, call other init functions
	 */
	init() {
		this.container = $('.filter_container');
		this.fld_chain = this.container.find('[name="fld_filter_chain"]');
		this.fld_chain_link = this.container.find('[name="fld_filter_chain_link"]');

		this.fillQueuesTable();
		this.initFilter();
	}

}
