import ChainAjax from './ChainAjax';
import CategoryUlFold from './CategoryUlFold';
import Sortable from 'sortablejs';
import QueuesChecker from './ChainsPage/QueuesChecker';
import {getBootstrapAlert} from '../messageModal';
import Cookie from 'js-cookie';

export default class {

	fillChainsTable() {
		var that = this;
		var table = $('#tbl_chains');
		if (table.length === 0) {
			return;
		}
		var tbody = table.find('tbody');
		$.ajax({
			url: window.urls.ajax_index,
			method: 'post',
			dataType: 'json',
			data: {
				action: 'get_table'
			},
			success: function (response) {
				if (response.success) {
					that.updateNurseStatuses(response.nurse_statuses);
					tbody.html(response.html);
					window.setTimeout(function () {
						that.fillChainsTable();
					}, 5000);
				} else {
					// @todo: errorModal o.i.d. voor gebruiken:
					alert('Error!');
				}
			},
			error: function (x, y, z) {
				// @todo: errorModal o.i.d. voor gebruiken:
				alert('Error!');
				console.log(x, y, z);
			}
		});
	}

	/**
	 * Helper function for fillChainsTable(): handle nurse statuses, update
	 * icons for them
	 * @param {object} statuses
	 */
	updateNurseStatuses(statuses) {
		var container = $('#nurse_statuses');
		$(['chains', 'api_bridge']).each(function () {
			var icon = container.find('.nurse_status.' + this);
			icon.removeClass('is_dead');
			icon.removeClass('is_active');
			if (statuses[this]) {
				icon.addClass('is_active');
			} else {
				icon.addClass('is_dead');
			}
		});
	}

	initChainsTableFold() {
		var that = this;
		var table = $('#tbl_chains');
		if (table.length === 0) {
			return;
		}

		table.on('click', '.cmd_chains_fold', function (e) {
			e.preventDefault();
			var clik_elem = $(this);
			var chain_row = clik_elem.closest('tr');
			var tbody = chain_row.closest('tbody');

			var chain_id = chain_row.data('chain-id');
			var link_rows = tbody.find('tr.chain_link[data-chain-id="' + chain_id + '"]');

			if (chain_row.hasClass('out')) {
				link_rows.hide();
				chain_row.removeClass('out');
				clik_elem.text('-');
				that.tableFoldRemoveFromCookie(chain_id);
			} else {
				link_rows.show();
				chain_row.addClass('out');
				clik_elem.text('+');
				that.tableFoldAddToCookie(chain_id);
			}
		});
	}

	/**
	 * Helper function: get array of Chain ID's from cookies: chain ID in this
	 * list should be open/out/shown, closed otherwise
	 * @returns {object} Array of Chain ID's
	 */
	tableFoldGetCookie() {
		var cookie = Cookie.getJSON('chains_folded_out');
		if (typeof cookie === 'object') {
			return cookie;
		}
		return [];
	}

	/**
	 * Helper function for initChainsTableFold(): a chain is folded in (chain
	 * links are hidden), remove its ID from the cookie
	 * @param {int} chain_id
	 * @returns {void}
	 */
	tableFoldRemoveFromCookie(chain_id) {
		var cookie = this.tableFoldGetCookie();
		var arr_pos = $.inArray(chain_id, cookie);
		if (arr_pos !== -1) {
			cookie = cookie.filter(function (value) {
				return parseInt(chain_id) !== parseInt(value);
			});
		}
		Cookie.set('chains_folded_out', cookie);
	}

	/**
	 * Helper function for initChainsTableFold(): a chain is folded out (chain
	 * links are shown), add its ID to the cookie
	 * @param {int} chain_id
	 * @returns {void}
	 */
	tableFoldAddToCookie(chain_id) {
		var cookie = this.tableFoldGetCookie();
		var arr_pos = $.inArray(chain_id, cookie);
		if (arr_pos === -1) {
			cookie.push(chain_id);
		}
		Cookie.set('chains_folded_out', cookie);
	}

	initChainsTableClicks() {
		var table = $('#tbl_chains');
		if (table.length === 0) {
			return;
		}

		table.on('click', '.cmd_stop_chain, .cmd_start_chain, .cmd_stop_chain_link, .cmd_start_chain_link, .cmd_restart_chain_link', function (e) {
			var cmd = new ChainAjax();
			cmd.setClickedElem(this, e).run();
			return false;
		});
	}

	initModulesTableFold() {
		var list = $('#ul_modules');
		if (list.length === 0) {
			return;
		}

		var fold = new CategoryUlFold(list);
		fold.init();
		fold.fireAll();
	}

	initModulesTableSortable() {
		var that = this;
		var modules_elem = $('#ul_modules');
		var chain_links_elem = $('#ul_chain_links');
		if (modules_elem.length === 0 || chain_links_elem.length === 0) {
			return;
		}

		// Modules tree: make draggable, not sortables
		var sortable = Sortable.create(modules_elem[0], {
			group: {
				name: 'grp_ul_modules',
				pull: 'clone',
				put: 'grp_chain_links',
				revertClone: true
			},
			sort: false,
			handle: '.cmd_draggable_module',
			animation: 100,
			onAdd: function (event) {
				var new_li_elem = $(event.item);
				var chain_link_id = new_li_elem.data('chain-link-id');
				new_li_elem.remove();

				var orig_elems = chain_links_elem.find('li');
				for (var i = 0; i < orig_elems.length; i++) {
					var orig_elem = $(orig_elems[i]);
					if (orig_elem.data('chain-link-id') === chain_link_id) {
						orig_elem.remove();
					}
				}
				that.onChainLinksDragEnd();
			}
		});

		// Chain links: make sortable, accept modules as new chain links
		var sortable = Sortable.create(chain_links_elem[0], {
			group: {
				name: 'grp_chain_links',
				put: 'grp_ul_modules'
			},
			handle: '.cmd_draggable_chain_link',
			animation: 100,
			onAdd: function () {
				that.onChainLinksDragEnd();
			},
			onUpdate: function () {
				that.onChainLinksDragEnd();
			}
		});
	}

	onChainLinksDragEnd() {
		var form_data = this.getFormDataForSaveChainLinks();

		$.ajax({
			url: window.urls.base_url + 'chain_links/ajax_edit',
			method: 'post',
			dataType: 'json',
			data: form_data,
			success: function (response) {
				if (response.success) {
					$('#ul_chain_links').html(response.chain_links_html);
				} else {
					// @todo: errorModal o.i.d. voor gebruiken:
					alert('Error!');
				}
			},
			error: function (x, y, z) {
				// @todo: errorModal o.i.d. voor gebruiken:
				alert('Error!');
				console.log(x, y, z);
			}
		});
	}

	getFormDataForSaveChainLinks() {
		var li_elems = $('#ul_chain_links > li');
		var links = [];
		for (var i = 0; i < li_elems.length; i++) {
			var li_elem = $(li_elems[i]);
			var link = {
				ord_idx: i,
				chain_link_id: li_elem.data('chain-link-id'),
				module_id: li_elem.data('module-id')
			};
			links.push(link);
		}

		var form_data = {
			action: 'save_chain_links',
			chain_id: $('input[name="id"]').val(),
			chain_links: links
		};
		return form_data;
	}

	initQueuesChecker() {
		var that = this;
		var checker = new QueuesChecker();
		$('.cmd_check_queues').on('click', function () {
			checker.run();
		});
	}

	onSubmitCopyChainModalForm(that, form) {
		var modal = $('#modal_copy_chain');
		modal.find('.copy_status_spinner').show();

		$.ajax({
			url: window.urls.ajax_edit,
			method: 'post',
			dataType: 'json',
			data: $(form).serialize(),
			success: function (response) {
				if (response.success) {
					// Notify user of success by showing an alert
					var alert_html = getBootstrapAlert('Chain gekopieerd', 'success');
					$('#notices_container').html(alert_html);

					// Reload overview
					that.fillChainsTable();

					// Close modal, done
					modal.modal('hide');
					return;
				}

				// No success, notify user
				// @todo: errorModal o.i.d. voor gebruiken:
				alert(response.error_msg);
			},
			error: function () {
				alert('ERROR!');
			}
		});
		return false;
	}

	onClickCopyChainButton(that, button) {
		var tr = button.closest('tr');
		var chain_id = $(tr).data('chain-id');
		var modal = $('#modal_copy_chain');

		modal.find('input[name="chain_id"]').val(chain_id);

		$.ajax({
			url: window.urls.ajax_index,
			method: 'post',
			dataType: 'json',
			data: {
				action: 'get_copy_chain_modal_html',
				chain_id: chain_id
			},
			success: function (response) {
				if (response.success) {
					// checkme:
					modal.find('.modal-body').html(response.html);
					modal.modal('show');
				} else {
					alert('ERROR!');
				}
			},
			error: function () {
				alert('ERROR!');
			}
		});
	}

	initCopyChainModal() {
		var that = this;
		var modal = $('#modal_copy_chain');

		modal.find('form').on('submit', function () {
			that.onSubmitCopyChainModalForm(that, this);
		});

		$('#tbl_chains').on('click', '.cmd_copy_chain_modal', function () {
			that.onClickCopyChainButton(that, this);
			return false;
		});

		modal.on('show.bs.modal', function () {
			modal.find('.copy_status_spinner').hide();
		});
	}

	init() {
		var that = this;

		this.fillChainsTable();
		this.initChainsTableFold();
		this.initChainsTableClicks();
		this.initModulesTableFold();
		this.initModulesTableSortable();
		this.initQueuesChecker();
		this.initCopyChainModal();
	}

}
