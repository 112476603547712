export default class {

	constructor($table) {
		this.$table = $table;
	}

	init() {
		this.$table.on('click', '.cmd_category_fold', function (e) {
			e.preventDefault();
			var clik_elem = $(this);
			var category_row = clik_elem.closest('tr');
			var tbody = category_row.closest('tbody');

			var category_id = category_row.data('category-id');
			var link_rows = tbody.find('tr.module[data-category-id="' + category_id + '"]');

			if (category_row.hasClass('out')) {
				link_rows.hide();
				category_row.removeClass('out');
				clik_elem.text('-');
			} else {
				link_rows.show();
				category_row.addClass('out');
				clik_elem.text('+');
			}
		});
	}

	fireAll() {
		this.$table.find('.cmd_category_fold').trigger('click');
	}

}
