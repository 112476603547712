export default class {

	constructor(buttons) {
		var that = this;
		buttons.on('click', function (e) {
			return that.onClickButton(e, $(this));
		});
	}

	onClickButton(event, button) {
		event.preventDefault();

		var has_msg = typeof button.data('del-msg') !== 'undefined';
		if (has_msg && !confirm(button.data('del-msg'))) {
			return false;
		}

		// Create new form element
		var form = $('<form method="post">');
		form.attr('action', button.attr('href'));

		// Create ID field, add to form
		var id_field = $('<input type="hidden" name="id" />').val(button.data('del-id'));
		form.append(id_field);

		// Submit form
		$('body').append(form);
		form.submit();
	}

}
