import {getBootstrapAlert} from '../../messageModal';

export default class {

	fillTable() {
		var filter = $('.filter_container');
		var form_data = {
			action: 'get_entries_html',
			is_checked: +filter.find('input[name="fld_filter_is_checked"]').prop('checked')
		};
		$.ajax({
			url: '/api/failed_log/ajax_index',
			method: 'post',
			dataType: 'json',
			data: form_data,
			success: function (response) {
				$('#tbl_failed_log_entries tbody').replaceWith(response.html);
			},
			error: function (x, y, z) {
				console.log(x, y, z);
				alert('Error!');
			}
		});
	}

	onClickCmdBeautifyXml(that, clicked_elem) {
		var button = $(clicked_elem);
		var container = button.closest('div.form-group');
		var textarea = container.find('textarea');

		$.ajax({
			url: '/api/failed_log/ajax_index',
			method: 'post',
			dataType: 'json',
			data: {
				action: 'beautify_xml',
				xml_str: textarea.val()
			},
			success: function (response) {
				textarea.val(response.xml);
			},
			error: function (x, y, z) {
				console.log(x, y, z);
				alert('Error!');
			}
		});
	}

	initBeautifyXml() {
		var that = this;
		$('.cmd_beautify_xml').on('click', function () {
			that.onClickCmdBeautifyXml(that, this);
		});
	}

	onClickCmdApplyExtractor() {
		$.ajax({
			url: '/api/failed_log/ajax_index',
			method: 'post',
			dataType: 'json',
			data: {
				action: 'apply_extractor',
				entry_id: $('input[name="id"]').val()
			},
			success: function (response) {
				var modal = $('#failed_log_msg_modal');
				var modal_label = $('#failed_log_msg_modal_label');
				var modal_body = $('#failed_log_msg_modal_body');
				modal_body.empty();

				if (response.success) {
					modal_body.html(response.html);
					modal_label.text('Data gevonden');
				} else {
					var alert_html = getBootstrapAlert(response.error_msg, 'danger');
					modal_body.html(alert_html);
					modal_label.text('Fout gevonden');
				}

				modal.modal('show');
			},
			error: function (x, y, z) {
				console.log(x, y, z);
				alert('Error!');
			}
		});
	}

	initApplyExtractor() {
		var that = this;
		$('.cmd_apply_extractor').on('click', function () {
			that.onClickCmdApplyExtractor(that, this);
		});
	}

	/**
	 * Helper function for getTextFromExtractedDataTbl(): get text from table
	 * row from "extracted data" table
	 * @param {jQueryObject} tr Table row object
	 * @returns {String} Text
	 */
	getTextFromExtractedDataRow(tr) {
		var parts = [];
		var cells = tr.find('td');
		for (var i = 0; i < cells.length; i++) {
			parts.push('"' + $(cells[i]).text() + '"');
		}
		return parts.join(': ');
	}

	/**
	 * Helper function for initCopyFailedReason(): get text from "extracted
	 * data" table
	 * @param {jQueryObject} tbl_html Table object
	 * @returns {String} Text
	 */
	getTextFromExtractedDataTbl(tbl_html) {
		var trs = tbl_html.find('tbody tr');
		var lines = [];
		for (var i = 0; i < trs.length; i++) {
			lines.push(this.getTextFromExtractedDataRow($(trs[i])));
		}
		return lines.join(', ');
	}

	initCopyFailedReason() {
		var that = this;
		$('.cmd_copy_failed_reason').on('click', function () {
			var alert_html_str = $('#failed_log_msg_modal_body').html();
			var alert_html = $(alert_html_str);

			var alert_text = alert_html.text();
			if (alert_html.hasClass('tbl_extracted_data')) {
				alert_text = that.getTextFromExtractedDataTbl(alert_html);
			}

			$('textarea[name="failed_reason"]').val(alert_text);
			$('#failed_log_msg_modal').modal('hide');
		});
	}

	init() {
		this.fillTable();
		this.initBeautifyXml();
		this.initApplyExtractor();
		this.initCopyFailedReason();
	}

}
