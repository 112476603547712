export default class {

	onSubmitApiKeyModalForm(e, form) {
		e.preventDefault();
		var form_data = {
			action: 'get_new_api_key',
			key_length: form.find('input[name="key_length"]').val()
		};
		$.ajax({
			url: window.urls.ajax_index,
			method: 'post',
			dataType: 'json',
			data: form_data,
			success: function (response) {
				if (response.success) {
					$('#fld_api_key').val(response.new_api_key);
					$('#generate_api_key_modal').modal('hide');
				} else {
					// @todo: errorModal o.i.d. voor gebruiken:
					alert('Error!');
				}
			},
			error: function (x, y, z) {
				// @todo: errorModal o.i.d. voor gebruiken:
				alert('Error!');
				console.log(x, y, z);
			}
		});
	}

	initGenerateApiKeyModal() {
		var that = this;
		var modal = $('#generate_api_key_modal');
		modal.find('form').on('submit', function (e) {
			that.onSubmitApiKeyModalForm(e, $(this));
		});
	}

	init() {
		var that = this;
		that.initGenerateApiKeyModal();
	}

}
