export function messageModal(message, title, alert_class) {
	var modal = $('#message_modal');
	modal.find('#message_modal_label').text(title);
	var body = modal.find('#message_modal_body');
	body.text(message);

	body.removeClass('alert-danger');
	body.removeClass('alert-success');
	body.addClass('alert-' + alert_class);

	modal.modal('show');
}

export function successModal(message) {
	var title = 'Succes';
	messageModal(message, title, 'success');
}

export function errorModal(message) {
	var title = 'Fout opgetreden!';
	messageModal(message, title, 'danger');
}

export function confirmModal(message, after_confirm, title) {
	if (typeof title === 'undefined') {
		title = 'Bevestiging';
	}

	var modal = $('#confirm_modal');
	modal.find('#confirm_modal_body').html(message);
	modal.find('#confirm_modal_label').html(title);
	modal.data('after-confirm', after_confirm);
	modal.modal('show');
}

export function getBootstrapAlert(message, alert_class) {
	if (typeof alert_class === 'undefined') {
		alert_class = 'success';
	}
	var html = '';
	html += '<div class="alert alert-' + alert_class + '" role="alert">';
	html += message;
	html += '</div>';
	return html;
}
