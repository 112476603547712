export default class {

	getTextareaRows(min) {
		var height = parseInt($(window).height());
		height -= 300;
		var rows = Math.floor(height / 30);
		if (rows < min) {
			rows = min;
		}
		return rows;
	}

	onClickSelectFile(clicked_elem) {
		var that = this;
		$.ajax({
			url: window.urls.ajax_index,
			method: 'post',
			dataType: 'json',
			data: {
				action: 'get_file',
				connection_id: $('#fld_connection_id').val(),
				filename: $(clicked_elem).data('filename')
			},
			success: function (response) {
				var container = $('#file_view_container');
				container.empty();

				var textarea = $('<textarea class="form-control" />');
				textarea.prop('readonly', true);
				textarea.attr('rows', that.getTextareaRows(10));
				textarea.val(response.contents);

				var title = $('<h5 class="card-title" />').text(response.filename);

				var card_body = $('<div class="card-body" />');
				card_body.append(title);
				card_body.append(textarea);

				var card = $('<div class="card" />');
				card.append(card_body);

				container.append(card);
			},
			error: function () {
				alert('Error!');
			}
		});
	}

	loadFiles() {
		$.ajax({
			url: window.urls.ajax_index,
			method: 'post',
			dataType: 'json',
			data: {
				action: 'get_files',
				connection_id: $('#fld_connection_id').val()
			},
			success: function (response) {
				var container = $('#files_container');
				container.empty();
				for (var i = 0; i < response.files.length; i++) {
					var div = $('<div />');
					div.attr('class', 'clickable cmd_select_file');
					div.data('filename', response.files[i]);
					div.text(response.files[i]);
					container.append(div);
				}
			},
			error: function () {
				alert('Error!');
			}
		});
	}

	init() {
		var that = this;

		$('#files_container').on('click', '.cmd_select_file', function () {
			that.onClickSelectFile(this);
		});

		that.loadFiles();
	}

}
