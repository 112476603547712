export default class {

	init() {
		$('div.accordion').on('click', '.btn-link', function (e) {
			var card = $(this).closest('div.card');
			if (card.data('content_done') === true) {
				return;
			}

			var i = parseInt(card.data('i'));
			var accordion = card.closest('div.accordion');
			var message_id = accordion.data('msg-id');
			var form_data = {
				action: 'get_message_part',
				message_id: message_id,
				i: i
			};

			$.ajax({
				url: '/message_queue_log/ajax_index',
				method: 'post',
				dataType: 'json',
				data: form_data,
				success: function (response) {
					if (response.success) {
						var body = card.find('.card-body');
						body.html(response.html);
						card.data('content_done', true);
						return;
					}
					console.log(response);
					alert('ERROR!');
				},
				error: function (x, y, z) {
					console.log(x, y, z);
					alert('ERROR!');
				}
			});
		});
	}

}
