export default class {

	copyConnectionFieldTr() {
		this.module_event_tr = null;
		var table = $('.tbl_module_events');
		var trs = table.find('tbody > tr');
		var length = (trs.length - 1);
		if (length < 0) {
			return;
		}
		var last_tr = $(trs[length]);
		this.module_event_tr = last_tr.clone();
	}

	onClickAddConnectionField() {
		var clone = this.module_event_tr.clone();
		$('.tbl_module_events tbody').append(clone);
	}

	onClickDelConnectionField(clicked_elem) {
		$(clicked_elem).closest('tr').remove();
	}

	init() {
		var that = this;
		this.copyConnectionFieldTr();
		$('.tbl_module_events').on('click', '.cmd_add_module_event', function () {
			that.onClickAddConnectionField();
			return false;
		});
		$('.tbl_module_events').on('click', '.cmd_del_module_event', function () {
			that.onClickDelConnectionField(this);
			return false;
		});
	}
		
}
