export default class {

	setErrorOnTextarea(area, errors) {
		area.addClass('is-invalid');
		var err_msg = errors[0].message;
		var feedback_elem = area.closest('.form-group').find('.invalid-feedback');
		feedback_elem.text(err_msg);
	}

	onChangeInputXml(textarea) {
		var that = this;
		var $textarea = $(textarea);
		$textarea.removeClass('is-invalid is-valid');
		if ($textarea.val() === '') {
			return;
		}

		$.ajax({
			url: window.urls.ajax_index,
			method: 'post',
			dataType: 'json',
			data: {
				action: 'validate_xml',
				xml: $textarea.val()
			},
			success: function (response) {
				if (response.success) {
					$textarea.addClass('is-valid');
				} else {
					that.setErrorOnTextarea($textarea, response.errors);
				}
			},
			error: function (x, y, z) {
				// @todo: errorModal o.i.d. voor gebruiken:
				alert('Error!');
				console.log(x, y, z);
			}
		});
	}

	onClickRunXsltTest() {
		var that = this;
		var xml_area = $('#textarea_input_xml');
		var xslt_area = $('#textarea_xslt');
		var output_area = $('#textarea_output_xml');

		xml_area.removeClass('is-invalid is-valid');
		xslt_area.removeClass('is-invalid is-valid');
		output_area.removeClass('is-invalid is-valid');

		$.ajax({
			url: window.urls.ajax_index,
			method: 'post',
			dataType: 'json',
			data: {
				action: 'test_xslt',
				xml: xml_area.val(),
				xslt: xslt_area.val()
			},
			success: function (response) {
				if (response.success) {
					output_area.addClass('is-valid');
					output_area.val(response.transformed);
				} else {
					switch (response.stage) {
						case 'validate_xml':
							that.setErrorOnTextarea(xml_area, response.errors);
							break;
						case 'validate_xslt':
							that.setErrorOnTextarea(xslt_area, response.errors);
							break;
						default:
							that.setErrorOnTextarea(output_area, response.errors);
							break;
					}
				}
			},
			error: function (x, y, z) {
				// @todo: errorModal o.i.d. voor gebruiken:
				alert('Error!');
				console.log(x, y, z);
			}
		});
	}

	init() {
		var that = this;

		$('#textarea_input_xml').on('change', function () {
			that.onChangeInputXml(this);
		});

		$('#textarea_xslt').on('change', function () {
			that.onChangeInputXml(this);
		});

		$('.cmd_run_xslt_test').on('click', function () {
			that.onClickRunXsltTest();
		});
	}

}