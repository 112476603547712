import OutEventsPromise from './Promises/OutEvents';
import ExchangePromise from './Promises/Exchange';
import QueuesPromise from './Promises/Queues';
import BindingsPromise from './Promises/Bindings';

export default class {

	constructor() {
		this.modal = $('#modal_queues_checker');
	}

	setStatusOnElem(elem, new_status) {
		elem.removeClass('todo busy ok nok');
		elem.addClass(new_status);
	}

	getModal() {
		return this.modal;
	}

	getFormDataParams(action) {
		var form_data = {
			action: action,
			chain_id: $('form').find('input[name="id"]').val()
		};
		return $.param(form_data);
	}

	getRequest() {
		var req = new XMLHttpRequest();
		req.open('POST', '/chains/ajax_index', true);
		req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		return req;
	}

	getPromises() {
		var promises = [];

		// check_out_events
		var out_events = new OutEventsPromise(this, 'check_out_events');
		promises.push(out_events.getPromise());

		// check_exchange
		var exchange = new ExchangePromise(this, 'check_exchange');
		promises.push(exchange.getPromise());

		// check_queues
		var queues = new QueuesPromise(this, 'check_queues');
		promises.push(queues.getPromise());

		// check_bindings
		var bindings = new BindingsPromise(this, 'check_bindings');
		promises.push(bindings.getPromise());

		return promises;
	}

	run() {
		this.modal.modal('show');
		Promise.all(this.getPromises())
				.then(function (fulfilled) {
					console.log(fulfilled);
				})
				.catch(function (error) {
					console.log(error);
				});
	}
}