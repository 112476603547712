require('bootstrap');
require('../css/main.scss');

// FontAwesome
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas, faStroopwafel } from '@fortawesome/free-solid-svg-icons';
library.add(fas, faStroopwafel);
dom.watch();

import DeleteButton from './DeleteButton';

import ChainsPage from './pages/ChainsPage';
import CustomersPage from './pages/CustomersPage';
import ConnectionsPage from './pages/ConnectionsPage';
import QueuesPage from './pages/QueuesPage';
import ModulesPage from './pages/ModulesPage';
import CategoryFold from './pages/CategoryFold';
import XsltTester from './pages/XsltTester';
import MessageQueueLogPage from './pages/MessageQueueLogPage';
import FailedLogPage from './pages/api/FailedLogPage';
import XsltFileViewerPage from './pages/XsltFileViewerPage';


function getBaseUrl() {
	if (!window.location.origin) {
		var url = window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
	} else {
		var url = window.location.origin;
	}
	if (url === 'http://localhost:8888') {
		url += '/portalgarden/chameleon/public';
	}
	if (url === 'http://localhost') {
		url += '/chameleon/www/public';
	}
	return url;
}


$(function () {
	// Javascript to enable link to tab
	if (window.location.hash !== '') {
		$('.nav-tabs a[href="' + window.location.hash + '"]').tab('show');
	}

	// Change hash for page-reload
	$('.nav-tabs a').on('shown.bs.tab', function (e) {
		window.location.hash = e.target.hash;
	});

	// Init Delete buttons
	new DeleteButton($('.cmd_btn_delete'));

	switch ($('body').data('page')) {
		case 'chains':
			var page = new ChainsPage();
			page.init();
			break;
		case 'customers':
			var page = new CustomersPage();
			page.init();
			break;
		case 'modules':
			var table = $('#tbl_modules');
			var fold = new CategoryFold(table);
			fold.init();
			var page = new ModulesPage();
			page.init();
			break;
		case 'connections':
			var page = new ConnectionsPage();
			page.init();
			break;
		case 'queues':
			var page = new QueuesPage();
			page.init();
			break;
		case 'message_queue_log':
			var page = new MessageQueueLogPage();
			page.init();
			break;
		case 'xslt_tester':
			var page = new XsltTester();
			page.init();
			break;
		case 'failed_log':
			var page = new FailedLogPage();
			page.init();
			break;
		case 'xslt_file_viewer':
			var page = new XsltFileViewerPage();
			page.init();
			break;
		default:
			console.log($('body').data('page'));
	}
});
