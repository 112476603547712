export default class {

	constructor($list) {
		this.$list = $list;
	}

	init() {
		this.$list.on('click', '.cmd_category_fold', function (e) {
			e.preventDefault();
			var clik_elem = $(this);
			var category_row = clik_elem.closest('li');
			var list = category_row.closest('ul');

			var category_id = category_row.data('category-id');
			var link_rows = list.find('li.module[data-category-id="' + category_id + '"]');

			if (category_row.hasClass('out')) {
				link_rows.hide();
				category_row.removeClass('out');
				clik_elem.text('-');
			} else {
				link_rows.show();
				category_row.addClass('out');
				clik_elem.text('+');
			}
		});
	}

	fireAll() {
		this.$list.find('.cmd_category_fold').trigger('click');
	}

}
