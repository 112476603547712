export default class {

	constructor(parent, action) {
		this.parent = parent;
		this.action = action;
		this.elem = parent.getModal().find('.' + action);
		this.error_cnt = this.elem.find('.error_container');
	}

	setStatusOnElem(new_status) {
		this.parent.setStatusOnElem(this.elem, new_status);
	}

	getFormDataParams() {
		return this.parent.getFormDataParams(this.action);
	}

	handleResponse(that, req, resolve, reject) {
		if (req.status === 200) {
			var response = JSON.parse(req.response);
			if (response.success) {
				that.setStatusOnElem('ok');
				resolve(req.response);
			} else {
				that.setStatusOnElem('nok');

				var duplicates = response.extra_data.duplicates;
				var text = '';
				text += 'Dubbel:';
				for (var i = 0; i < duplicates.length; i++) {
					text += '<br>' + duplicates[i];
				}
				that.error_cnt.html(text);

				reject(Error(response.error_msg));
			}
		} else {
			that.setStatusOnElem('nok');
			reject(Error(req.statusText));
		}
	}

	handleError(that, req, resolve, reject) {
		that.setStatusOnElem('nok');
		reject(Error("Network Error"));
	}

	getPromise() {
		var that = this;

		// Return a new promise.
		return new Promise(function (resolve, reject) {
			that.setStatusOnElem('busy');
			that.error_cnt.empty();

			// Do the usual XHR stuff
			var req = that.parent.getRequest();

			// Handle normal response
			req.onload = function () {
				that.handleResponse(that, req, resolve, reject);
			};

			// Handle network errors
			req.onerror = function () {
				that.handleEror(that, req, resolve, reject);
			};

			// Make the request
			req.send(that.getFormDataParams());
		});
	}

}
