import {successModal, errorModal} from '../messageModal';

export default class {

	constructor() {
		this.clicked_elem = null;
		this.form_data = {
			action: null,
			chain_id: null,
			chain_link_id: null
		};
	}

	setAction(action) {
		this.form_data.action = action;
		return this;
	}

	setChainId(chain_id) {
		this.form_data.chain_id = chain_id;
		return this;
	}

	setChainLinkId(chain_link_id) {
		this.form_data.chain_link_id = chain_link_id;
		return this;
	}

	setClickedElem(clicked_elem, e) {
		// Set clicked elem
		this.clicked_elem = clicked_elem;

		// Get row for button
		var button = $(clicked_elem);
		var row = button.closest('tr');

		// Set form data vars
		this.setChainId(row.data('chain-id'));
		this.setChainLinkId(row.data('chain-link-id'));
		this.setAction(this.getActionFromElem(e.target));

		// Done
		return this;
	}

	getActionFromElem(elem) {
		var class_list = elem.className.split(/\s+/);
		for (var i = 0; i < class_list.length; i++) {
			if (class_list[i].substr(0, 4) === 'cmd_') {
				return class_list[i].substr(4);
			}
		}
		return '';
	}

	run() {
		$.ajax({
			url: 'chains/ajax_edit',
			method: 'post',
			dataType: 'json',
			data: this.form_data,
			success: function (response) {
				if (response.success) {
					var msg = 'Commando is gestuurd; het kan even duren voor dit zichtbaar wordt.';
					successModal(msg);
				} else {
					errorModal(response.error_msg);
				}
			},
			error: function (x, y, z) {
				// @todo: errorModal o.i.d. voor gebruiken:
				alert('Error!');
				console.log(x, y, z);
			}
		});
	}

}
